import React from "react";
import tw from "twin.macro";


const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-10`;
const Solution = tw.div`w-11/12 mt-10`;
const ProblemHeader = tw.div`w-11/12 text-2xl md:text-4xl font-black mb-5`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const SolutionHeader = tw.div`w-full text-3xl md:text-5xl font-black font-serif`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl mb-5`;


const BelowTheFoldSlack = () => {

    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemHeader>
                    Slack is a fast track to burnout.
                </ProblemHeader>
                <ProblemText>
                    A channel is, at best, a bunch of people scribbling on the wall.
                </ProblemText>
                <ProblemText>
                    Threads make it impossible to see what's going on.
                </ProblemText>
                <ProblemText>
                    Notifications are a study in developer torture.
                </ProblemText>

            </Problem>
            <SolutionHeader>
                Uclusion enforces more purposeful communication.
            </SolutionHeader>
            <Solution>

                <SolutionText>
                    You can approve a job, ask a question, make a suggestion, report progress, or give feedback.
                </SolutionText>
                <SolutionText>
                    All presorted in your inbox, with actionable tasks ready for you.
                </SolutionText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldSlack;