import React from "react";
import tw from "twin.macro";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-10`;
const Solution = tw.div`w-11/12 mt-10`;
const ProblemHeader = tw.div`w-11/12 text-2xl md:text-4xl font-black mb-5`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const SolutionHeader = tw.div`w-full text-3xl md:text-5xl font-black font-serif`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl mb-5`;
const SolutionEmphasis = tw.div`w-11/12 font-black text-xl md:text-2xl mb-5`;

const BelowTheFoldScrum = () => {

    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemHeader>
                    Scrum is a failure.
                </ProblemHeader>
                <ProblemText>
                    Scrum fails because it assumes everyone is most efficient when they are in the same room at the
                    same time.
                </ProblemText>
            </Problem>

            <SolutionHeader>
                Development with Uclusion assumes you're most efficient when you're free to focus.
            </SolutionHeader>
            <Solution>
                <SolutionEmphasis>
                    No scheduled meetings, no unprepared discussions, and no interruptions.
                </SolutionEmphasis>
                <SolutionText>
                    Create a job and your team asynchronously votes if its worth doing.
                </SolutionText>
                <SolutionText>
                    Finish a task on a job and feedback is gathered with the push of a button.
                </SolutionText>
                <SolutionText>
                    Questions, answers, suggestions and delivery estimates land in your wizard powered inbox <em>with
                    context</em>.
                </SolutionText>
                <SolutionText>
                    ... and much, much more.
                </SolutionText>
            </Solution>
        </TextContent>

    );
};

export default BelowTheFoldScrum;