import React, {useState} from "react";
import tw from "twin.macro";
import BelowTheFoldScrum from "./BelowTheFoldScrum";
import BelowTheFoldJira from "./BelowTheFoldJira";
import BelowTheFoldSlack from "./BelowTheFoldSlack";


const Container = tw.div`h-full md:h-screen mb-0`;
const ScalableContainer = tw.div`h-full flex justify-center items-center`;
const LeftAndRight = tw.div`w-2/3 h-full flex justify-between`;

const LeftNav = tw.div`w-1/4 flex-col justify-center items-end`;
const RightPage = tw.div`w-3/4`;

export const TabLink = tw.a`
  text-2xl tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;
export const SelectedTab = tw.a`

  text-2xl tracking-wide transition duration-300
  border-b-2 border-transparent border-black text-primary-500
`;
const ButtonContainer = tw.div`w-full mt-10 mb-10`;


const BelowTheFold = () => {
    const [visiblePage, setVisiblePage] = useState('scrum');
    const ScrumButton = visiblePage === 'scrum' ? SelectedTab : TabLink;
    const SlackButton = visiblePage === 'slack' ? SelectedTab : TabLink;
    const JiraButton = visiblePage === 'jira' ? SelectedTab : TabLink;

    return (
        <Container>
            <ScalableContainer>
                    <LeftAndRight>
                        <LeftNav>
                            <ButtonContainer>
                                <ScrumButton onClick={() => setVisiblePage('scrum')}>No Scrum</ScrumButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <SlackButton onClick={() => setVisiblePage('slack')}>No Slack</SlackButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <JiraButton onClick={() => setVisiblePage('jira')}>No Jira</JiraButton>
                            </ButtonContainer>
                        </LeftNav>
                        <RightPage>
                            {visiblePage === 'scrum' && <BelowTheFoldScrum/>}
                            {visiblePage === 'slack' && <BelowTheFoldSlack/>}
                            {visiblePage === 'jira' && <BelowTheFoldJira/>}
                        </RightPage>
                    </LeftAndRight>
                </ScalableContainer>
        </Container>
    );
};

export default BelowTheFold;