import {Avatar, Grid} from "@material-ui/core";
import David from "../../../assets/images/david.jpg";
import Ben from "../../../assets/images/ben.jpg";
import React from "react";
import tw from "twin.macro";

const Container = tw.div`h-screen mt-20 md:mt-0 md:h-full w-full`;

function AboutUs() {
    return (
        <Container>
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={1}
                style={{paddingBottom: '3rem'}}
            >
                <Grid item style={{paddingBottom: '2rem'}}>
                    <div style={{textAlign: 'center'}}>
                        <Avatar src={David} style={{width: '15rem', height: '15rem', margin: "auto"}}/>
                        <h3 style={{paddingTop: '0.5rem', marginTop: '0.6rem', margin: "auto"}}>
                            David Israel
                        </h3>
                        <h5 style={{padding: '0', marginBottom: '0', margin: "auto"}}>Co-Founder</h5>
                    </div>
                </Grid>
                <Grid item>
                    <div style={{textAlign: 'center'}}>
                        <Avatar src={Ben} style={{width: '15rem', height: '15rem', margin: "auto"}}/>
                        <h3 style={{paddingTop: '0.5rem', marginTop: '0.6rem', margin: "auto"}}>
                            Ben Follis
                        </h3>
                        <h5 style={{padding: '0', marginBottom: '0', margin: "auto"}}>Co-Founder</h5>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AboutUs;