import React from "react";
import tw from "twin.macro";
import Typewriter from 'typewriter-effect';
import {ArrowDownwardOutlined} from "@material-ui/icons";
import "./bounce.css";
const Container = tw.div`mt-10 w-full h-screen flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left`;
const Heading = tw.div`font-black text-left text-4xl lg:text-5xl text-center leading-tight tracking-wide`;
const SubHeading = tw.div`text-center font-normal h-24 lg:h-12`;
const CTA = tw.div`text-center font-normal text-2xl`;
const TypePaper = tw.div`text-2xl`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-4 text-2xl font-bold inline-block mr-2 rounded-xl`;
const Scroll = tw.div`mt-24 text-primary-500 text-center`;

const bullets = ["Decide outside of meetings.", "Gather feedback and answers as you work.", "Focus with reporting and alerting handled."];
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";


const AboveTheFold = ({
                    heading = (
                        <>
                            Maximize <span tw="text-primary-500">uninterrupted hours</span> coding.
                        </>

                    ),
                    subHeading = (
                        <>
                            <TypePaper>
                            <Typewriter
                                options={{
                                    delay: 60,
                                    pauseFor: 4000,
                                    deleteSpeed: 45,
                                    strings: bullets,
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                            </TypePaper>
                        </>
                    ),
                    cta = (
                        <>
                            <PrimaryButton as="a" href={primaryButtonUrl}>
                                Sign Up
                            </PrimaryButton>
                            <span tw="text-2xl">
                                for a sandbox demo beyond Scrum, Slack, and Jira
                            </span>
                        </>

                    )
                }) => {

    return (
        <Container>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        <SubHeading>{subHeading}</SubHeading>
                        <CTA>{cta}</CTA>
                        <Scroll><div className="bouncer"><ArrowDownwardOutlined/></div></Scroll>
                    </TextContent>

        </Container>
    );
};

export default AboveTheFold;