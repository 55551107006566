import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold.js";
import tw from "twin.macro";
import Footer from "../../../shared/MiniCenteredFooter";
import BelowTheFold from "../../../shared/BelowTheFold";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Pricing from "../../../shared/Pricing";

const Page = tw.div`h-screen w-full relative`;
const Container = tw.div`h-full w-full -z-10`;
const PricingAnchor = tw.div`w-full -z-10`; // this takes 0 space in the layout but prevents the pricing text being under the menu
function Home() {

    return (
        <Page>
            <Header/>
            <Container>
                <AboveTheFold/>
                <Zoom>
                    <BelowTheFold/>
                </Zoom>
                <PricingAnchor id="pricing" style={{zIndex: -10, marginBottom: "150px", marginTop:"-150px"}}/>
                <Fade>
                    <Pricing />
                </Fade>
                <Footer/>
            </Container>

        </Page>
    );
}

export default Home;