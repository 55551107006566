import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`h-full -z-10 md:h-screen content-center`;
const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
const HeaderContainer = tw.div`flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex flex-col justify-items-stretch md:justify-center md:flex-row items-center md:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-3xl sm:text-5xl`}
  }
  .duration {
    ${tw`lowercase text-gray-600 font-medium text-sm tracking-widest mb-2`}
  }
  .mainFeature {
    ${tw`text-gray-600 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-700`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-4 text-2xl font-bold inline-block mr-2 rounded-xl`;


const Pricing =  ({
  subheading = "Pricing",
  heading = "Start claiming your time back",
  description = "Access all of Uclusion. No credit card required.",
}) => {
  const defaultPlans = [
      {
      name: "Small Team",
      price: "$5.99",
      buttonText: 'Start Now',
      buttonUrl: `https://production.uclusion.com/?utm_campaign=small}#signup`,
      mainFeature: "For a single team",
      featured: true,
      features: ["6 Months Trial", "Single Workspace", "3 Groups", "Slack and Loom integration", "Google and Github login", "10GB image and file storage"]
      },
    {
      name: "Medium Team",
      utm: 'medium',
      price: "$10.99",
      mainFeature: "For a small department",
      featured: true,
      buttonText: 'Start Now',
      buttonUrl: `https://production.uclusion.com/?utm_campaign=small}#signup`,
      features: ["All Small Team features", "5 Workspaces", "Unlimited Groups", "50GB image and file storage"]
    },
    {
      name: "Unlimited Plan",
      mainFeature: "For company wide usage",
      features: ["All Medium Team features", "Multiple Workspaces", "Custom integrations", "Custom SSO", "Unlimited image and file storage"],
      buttonText: 'Contact Us',
      buttonUrl: 'mailto:support@uclusion.com'
    }
  ];

  return (
    <Container>
        <HeaderContainer>
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {defaultPlans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                {plan.price && (
                    <div>
                      <div className="price">{plan.price}</div>
                      <div className="duration">per person/month</div>
                    </div>
                )}
                {!plan.price && (
                    <span className="priceAndDuration">
                      <span className="price">Contact Us</span>
                    </span>
                )}
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              {plan.buttonText &&
                  (<PlanAction>
                <PrimaryButton as="a" href={plan.buttonUrl}>
                  {plan.buttonText}
                </PrimaryButton>
              </PlanAction>)}
            </Plan>
          ))}
        </PlansContainer>
    </Container>
  );
};

export default Pricing;