import React from 'react';
import FormdataWizard from "react-formdata-wizard";
import Home from "../../desktop/components/Teams/Home";
import MobileHome from "../../mobile/components/Teams/Home";

function InputWizard(props) {
    const { isMobile } = props;
    const defaultFormData = {earlyFeedback: 50, valueCertainty: 50, improvement: 50,
        nothingToDo: 50, wasTouched: false}
    return (
        <FormdataWizard name="calculator_wizard" defaultFormData={defaultFormData}>
            {isMobile && (
                <MobileHome />
            )}
            {!isMobile && (
                <Home />
            )}
        </FormdataWizard>
    )
}

export default InputWizard;