import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Pricing from "../../../shared/Pricing";
import Footer from "../../../shared/MiniCenteredFooter";
import tw from "twin.macro";
import BelowTheFoldScrum from "../../../shared/BelowTheFoldScrum";
import BelowTheFoldJira from "../../../shared/BelowTheFoldJira";
import BelowTheFoldSlack from "../../../shared/BelowTheFoldSlack";
const PricingAnchor = tw.div`w-full -z-10`; // this takes 0 space in the layout but prevents the pricing text being under the menu
const PricingSpacer = tw.div`mt-5 mb-5`;
function MobileHome() {

    return (
        <>
            <Header />
            <div className="mobileContainer">
                <AboveTheFold/>
                <Zoom>
                    <BelowTheFoldScrum/>
                </Zoom>
                <Zoom>
                    <BelowTheFoldJira/>
                </Zoom>
                <Zoom>
                    <BelowTheFoldSlack/>
                </Zoom>
                <PricingAnchor id="pricing" style={{marginBottom: "150px", marginTop:"-150px"}}/>
                <Fade>
                    <Pricing />
                </Fade>
                <PricingSpacer/>
            </div>
            <Footer/>
        </>
    );
}

export default MobileHome;