import React, {useEffect, useState} from 'react';
import Header from './SiteHeader';
import blogFrPath from '../competition.md'
import ReactMarkdown from "react-markdown";
import Footer from "./MiniCenteredFooter";

function Competition() {
    const [blog, setBlog] = useState(null);
    useEffect(() => {
        fetch(blogFrPath).then((response) => response.text()).then((text) => {
            setBlog(text);
        });
        return () => {};
    }, []);

    return(
        <>
            <Header />
            <div className="blog">
                <ReactMarkdown source={blog} />
            </div>
            {blog && (
                <Footer />
            )}
        </>
    );
}

export default Competition;