import React from "react";
import tw from "twin.macro";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-10`;
const Solution = tw.div`w-11/12 mt-10`;
const ProblemHeader = tw.div`w-11/12 text-2xl md:text-4xl font-black mb-5`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const SolutionHeader = tw.div`w-full text-3xl md:text-5xl font-black font-serif`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl mb-5`;


const BelowTheFoldJira = () => {

    return (
        <TextContent style={{display: "flex", flexDirection: "column"}}>
            <Problem>
                <ProblemHeader>
                    Jira is built for everyone <em>but</em> developers.
                </ProblemHeader>
                <ProblemText>
                    It has burn down charts, projected completion charts, epics, sprints, and endless
                    flexibility.
                </ProblemText>
                <ProblemText>
                    Jira does not, however, make it easy for a developer to keep track of their work.
                </ProblemText>
            </Problem>
            <SolutionHeader>
                Uclusion's workflows are designed for developers, and developers only.
            </SolutionHeader>
            <Solution>
                <SolutionText>
                    Ask a question and your job's status also changes automatically.
                </SolutionText>
                <SolutionText>
                    Find a bug? Let Uclusion get it assigned for you.
                </SolutionText>
                <SolutionText>
                    Forget about a job? Uclusion reminds you in your inbox.
                </SolutionText>
                <SolutionText>
                    We deal with the <em>administrivia</em>, you deal with shipping product.
                </SolutionText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldJira;
