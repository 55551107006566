import React from 'react';
import {Route, Switch} from "react-router-dom";
import "../../desktop.css";
import InputWizard from "../../../shared/Calculator/InputWizard";
import Footer from "../../../shared/MiniCenteredFooter";
import Header from "../../../shared/SiteHeader";
import AboutUs from "./AboutUs";
import tw from "twin.macro";

const Page = tw.div`h-screen w-full relative`;
const Container = tw.div`h-full -z-50 mt-20`;

function TeamApp(props) {
    return (
        <Switch>
            <Route exact path="/">
                <InputWizard/>
            </Route>
            <Route path="/aboutus">
                <Page>
                    <Header/>
                    <Container>
                        <AboutUs/>
                    </Container>
                    <Footer/>
                </Page>
            </Route>
        </Switch>
    );
}

export default TeamApp;