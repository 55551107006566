import React from 'react';
import {Route, Switch} from "react-router-dom";
import Competition from "../../../shared/Competition";
import '../../mobile.css';
import InputWizard from "../../../shared/Calculator/InputWizard";
import Footer from "../../../shared/MiniCenteredFooter";
import Header from "../../../shared/SiteHeader";
import AboutUs from "./AboutUs";

function TeamApp(props){
    const { utm } = props;
    return (
        <Switch>
            <Route exact path="/">
                <InputWizard isMobile />
            </Route>
            <Route path="/aboutus">
                <Header />
                <AboutUs />
                <Footer />
            </Route>
            <Route path="/competition">
                <Competition utm={utm} />
            </Route>
        </Switch>
    );
}

export default TeamApp;